/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import Collapse from '@material-ui/core/Collapse'

import { FiDollarSign, FiX } from 'react-icons/fi'
import { RiQuestionLine } from 'react-icons/ri'
import usePersistedState from '../../hooks/usePersistedState'
import { formatValue } from '../../utils/formatValues'

import Button from '../../components/Button'
import Header from '../../components/Header'

import {
  Container,
  Content,
  BtnVoltar,
  ButtonController,
  URContent,
  URBox,
  InfoContent,
  Line,
  NoContribCheck,
} from './styles'
import { UserDetails } from '../../utils/interfaces'
import Input from '../../components/Input'
import getValidationErrors from '../../utils/getValidationErrors'
import { ValorMascarar } from '../../utils/masks'
import InputRange from '../../components/InputRange'
import Modal from '../../components/Modal'
import { FormSteps } from '../../components/FormSteps'

const ContributionP2: React.FC = () => {
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [vlrCtbBasica, setVlrCtbBasica] = useState(
    userDetails.contribuicaoBasica > 0 ? userDetails.contribuicaoBasica : 0,
  )
  const [vlrCtbPatrocinadora, setVlrCtbPatrocinadora] = useState(
    userDetails.contribuicaoPatrocinadora > 0
      ? userDetails.contribuicaoPatrocinadora
      : 0,
  )
  const [vlrCtbTotal, setVlrCtbTotal] = useState(
    userDetails.contribuicaoBasica * 2 > 0
      ? userDetails.contribuicaoBasica * 2
      : 0,
  )
  const [pctCtbBas, setPctCtbBas] = useState(
    userDetails.pctContribuicaoBasica > 0
      ? userDetails.pctContribuicaoBasica
      : 2,
  )

  const [pctCtbSup, setPctCtbSup] = useState(
    userDetails.pctContribuicaoSuplementar > 0
      ? userDetails.pctContribuicaoSuplementar
      : 0,
  )
  const [vlrCtbSup, setVlrCtbSup] = useState(
    userDetails.contribuicaoSuplementar > 0
      ? userDetails.contribuicaoSuplementar
      : 0,
  )

  const [flagEdit, setFlagEdit] = usePersistedState<'S' | 'N' | ''>(
    'flagEdit',
    'N',
  )

  const [UR] = useState(5523.58) // 5272.5)

  const [minContrib] = useState(UR * 0.02)
  const [visibility, setVisibility] = useState(false)
  const [salarioValue, setSalarioValue] = useState(
    userDetails.salario > 0 ? userDetails.salario : 0,
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  /* CALCULO DE PARCELAS/FAIXAS DE CONTRIBUIÇÃO */
  useEffect(() => {
    const vlrAplicavel = salarioValue > UR ? salarioValue - UR : UR
    let vlrContribPartic = vlrAplicavel * (pctCtbBas / 100)

    vlrContribPartic =
      vlrContribPartic < minContrib ? minContrib : vlrContribPartic
    // switch (pctCtbBas) {
    //   case 0:
    //     vlrContribPatroc = salarioValue * 0.01
    //     break
    //   default:
    //     vlrContribPatroc =
    //       pctCtbBas > 1 ? vlrContribPartic * 0.8 : vlrContribPartic
    // }
    // console.log(minContrib, vlrContribPartic)
    setVlrCtbBasica(vlrContribPartic)
    const vlrContribPatroc = vlrContribPartic * 1.5
    setVlrCtbPatrocinadora(vlrContribPatroc)
    setVlrCtbTotal(vlrContribPartic + vlrContribPatroc)
  }, [
    salarioValue,
    pctCtbBas,
    UR,
    vlrCtbBasica,
    vlrCtbPatrocinadora,
    minContrib,
  ])

  // const minusContrib = useCallback(() => {
  //   if (pctCtbBas <= 0.5) {
  //     setPctCtbBas(0.5)
  //   } else {
  //     const value = pctCtbBas > 7.5 ? 7.5 : pctCtbBas - 0.5
  //     setPctCtbBas(value)
  //   }
  // }, [pctCtbBas])
  // const plusContrib = useCallback(() => {
  //   if (pctCtbBas >= 7.5) {
  //     setPctCtbBas(7.5)
  //   } else {
  //     const value = pctCtbBas < 0.5 ? 0.5 : pctCtbBas + 0.5
  //     setPctCtbBas(value)
  //   }
  // }, [pctCtbBas])

  useEffect(() => {
    const value = salarioValue * (pctCtbSup / 100)
    setVlrCtbSup(value)
  }, [pctCtbSup, salarioValue])

  // const minusContribSup = useCallback(() => {
  //   if (pctCtbSup <= 0) {
  //     setPctCtbSup(0)
  //   } else {
  //     setPctCtbSup(pctCtbSup - 0.5)
  //   }
  // }, [pctCtbSup])
  // const plusContribSup = useCallback(() => {
  //   if (pctCtbSup >= 10) {
  //     setPctCtbSup(10)
  //   } else {
  //     setPctCtbSup(pctCtbSup + 0.5)
  //   }
  // }, [pctCtbSup])

  const mudarSalario = useCallback(valor => {
    const v = valor.replace(',', '').replaceAll('.', '')
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(v) || v === '' || v === undefined) {
      setSalarioValue(0)
    } else {
      const m = Math.floor(v.length - 2)
      const a = `${v.substr(0, m)}.${v.substr(m)}`
      const f = parseFloat(a)
      setSalarioValue(f)
      setPctCtbSup(0)
    }
  }, [])

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          salario: Yup.string()
            .test('', 'Salário inválido.', () => salarioValue <= 100000)
            .required('Campo obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        await setUserDetails({
          ...userDetails,
          salario: salarioValue,
          contribuicaoBasica: vlrCtbBasica,
          contribuicaoPatrocinadora: vlrCtbPatrocinadora,
          pctContribuicaoBasica: pctCtbBas,
          contribuicaoSuplementar: vlrCtbSup,
          pctContribuicaoSuplementar: pctCtbSup,
        })

        if (flagEdit === 'S') {
          setFlagEdit('N')
          history.push('/resume')
        } else {
          // history.push('/investor-profile')
          history.push('/investor-quiz')
        }
      } catch (err) {
        formRef.current?.setErrors(
          getValidationErrors(err as Yup.ValidationError),
        )
      }
    },
    [
      flagEdit,
      history,
      pctCtbBas,
      pctCtbSup,
      salarioValue,
      setFlagEdit,
      setUserDetails,
      userDetails,
      vlrCtbBasica,
      vlrCtbPatrocinadora,
      vlrCtbSup,
    ],
  )

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  const marks = []
  for (let i = 0.5; i <= 7.5; i += 0.5) {
    marks.push({ value: i, label: `${i.toString().replace('.', ',')}%` })
  }

  const marksSup = []
  for (let i = 0; i <= 10; i += 0.5) {
    marksSup.push({
      value: i,
      label: Math.floor(i) === i ? `${i.toString().replace('.', ',')}%` : '',
    })
  }

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            salario:
              userDetails.salario === undefined
                ? ''
                : ValorMascarar(userDetails.salario.toFixed(2).toString()),
          }}
        >
          <Content>
            <FormSteps currentStep={0} />
            <div className="app_title">
              <strong>Contribuição básica</strong>
              <RiQuestionLine onClick={() => setIsModalOpen(true)} />
            </div>
            {/* <p>
              Aqui você irá definir sua contribuição para o seu Plano de
              Previdência. Os percentuais definidos serão descontados
              mensalmente do seu salário. A patrocinadora também contribuirá
              mensalmente para a formação do seu benefício futuro com 80% da sua
              contribuição básica.
            </p> */}
            <Input
              icon={FiDollarSign}
              name="salario"
              mask="currency"
              type="text"
              placeholder="Qual o seu salário base R$?"
              onChange={e => mudarSalario(e.target.value)}
            />
            <Collapse in={salarioValue !== 0} className="collapse">
              <URContent>
                <div className="UR-info">
                  <p>Contribuição mensal</p>
                </div>
                <URBox>
                  <InputRange
                    name="rangeContribBasica"
                    functionChange={e => {
                      setPctCtbBas(e)
                    }}
                    start={0.5}
                    end={7.5}
                    initialValue={salarioValue >= UR ? pctCtbBas : 2}
                    step={0.5}
                    key={0}
                    marks={marks}
                    valueLabelDisplay="off"
                    disabled={salarioValue < UR}
                  />
                  {/*
                    <ButtonController
                      type="button"
                      disabled={pctCtbBas <= 0.5}
                      isSelected={pctCtbBas > 0.5}
                      onClick={() => minusContrib()}
                    >
                      <FiMinusCircle />
                    </ButtonController>
                    <strong>{pctCtbBas}%</strong>
                    <ButtonController
                      type="button"
                      disabled={pctCtbBas >= 7.5}
                      isSelected={pctCtbBas < 7.5}
                      onClick={() => plusContrib()}
                    >
                      <FiPlusCircle />
                    </ButtonController>
                   */}
                  <strong>{pctCtbBas}%</strong>
                </URBox>
              </URContent>
            </Collapse>

            <Line />

            <InfoContent>
              <div>
                <p>Contribuição básica do Participante: </p>
                <strong>{formatValue(vlrCtbBasica)}</strong>
              </div>
              <div>
                <p>Repique da empresa: </p>
                <strong>{formatValue(vlrCtbPatrocinadora)}</strong>
              </div>
            </InfoContent>
          </Content>

          <Collapse
            in={(salarioValue > 0 && pctCtbBas >= 7.5) || salarioValue <= UR}
          >
            <Content>
              <div className="app_title">
                <strong>Contribuição Voluntária</strong>
                <RiQuestionLine onClick={() => setIsModalOpen(true)} />
              </div>
              <p>
                Para contribuir ainda mais com a formação da sua reserva,
                cadastre uma contribuição adicional, correspondente a um
                percentual incidente sobre o seu salário. Sobre a Contribuição
                Voluntária não haverá contrapartida da Patrocinadora.
              </p>
              <Collapse in={!visibility} className="collapse">
                <URContent>
                  <div className="UR-info">
                    <p>
                      Com qual % sobre o seu salário você deseja contribuir
                      adicionalmente?
                    </p>
                  </div>
                  <URBox>
                    <InputRange
                      name="rangeContribSuplementar"
                      functionChange={e => setPctCtbSup(e)}
                      start={0}
                      end={10}
                      initialValue={pctCtbSup}
                      step={0.5}
                      key={0}
                      marks={marksSup}
                      valueLabelDisplay="off"
                      disabled={pctCtbBas < 7.5 && salarioValue > UR}
                    />
                    {/* <div className="contrib-sup">
                  <p>
                    Com qual % sobre o seu salário você deseja contribuir
                    adicionalmente?
                  </p>
                  <div>
                    <ButtonController
                      type="button"
                      disabled={pctCtbSup <= 0}
                      isSelected={pctCtbSup > 0}
                      onClick={() => minusContribSup()}
                    >
                      <FiMinusCircle />
                    </ButtonController>
                    <strong>{pctCtbSup}%</strong>
                    <ButtonController
                      type="button"
                      isSelected={pctCtbSup < 10}
                      disabled={pctCtbSup >= 10}
                      onClick={() => plusContribSup()}
                    >
                      <FiPlusCircle />
                    </ButtonController>
                  </div>
                </div> */}
                    <strong>{pctCtbSup}%</strong>
                  </URBox>
                  <Line />
                  <InfoContent>
                    <div>
                      <p>Valor da contribuição voluntária:</p>
                      <strong>{formatValue(vlrCtbSup)}</strong>
                    </div>
                  </InfoContent>
                </URContent>
                <Line />
              </Collapse>

              <NoContribCheck>
                <div>
                  <input
                    name="checkbox"
                    type="checkbox"
                    checked={visibility}
                    onChange={e => {
                      setVisibility(e.target.checked)
                      setPctCtbSup(0)
                      setVlrCtbSup(0)
                    }}
                  />
                  <small
                    onClick={() => {
                      setVisibility(!visibility)
                      setPctCtbSup(0)
                      setVlrCtbSup(0)
                    }}
                  >
                    Não quero cadastrar uma contribuição voluntária.
                  </small>
                </div>
              </NoContribCheck>

              <div className="rules">
                <small>
                  Está em dúvida? Não se preocupe, depois você poderá alterar
                  este valor sempre que desejar.
                </small>
              </div>
            </Content>
          </Collapse>
          <Content>
            <InfoContent>
              <div>
                <p>Desconto total:</p>
                <strong>{formatValue(vlrCtbBasica + vlrCtbSup)}</strong>
              </div>
            </InfoContent>
          </Content>
        </Form>

        <Button
          type="submit"
          color="green"
          onClick={handleClick}
          disabled={
            pctCtbBas === null ||
            pctCtbSup === null ||
            salarioValue < 1 ||
            (pctCtbBas === 5 && vlrCtbSup === 0 && !visibility)
          }
        >
          Próximo
        </Button>
        <BtnVoltar type="button" onClick={() => history.push('/welcome')}>
          &lt; Anterior
        </BtnVoltar>
        <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <FiX onClick={handleCloseModal} />
          <strong>SALÁRIO BASE</strong>
          <p>
            O salário informado corresponde ao último Salário Base de INSS,
            atualizado e registrado no sistema conforme dados informados pelo RH
            da Cargill. As contribuições mensais futuras serão calculadas com
            base no salário base de INSS atualizado (composto por todas as
            variáveis processadas na folha de pagamento, como férias, 13º, horas
            extras, entre outros).
          </p>
          <Line />
          <strong>VALOR MÍNIMO</strong>
          <p>
            Para funcionários com salário base abaixo de 1 Unidade
            Previdenciária - {formatValue(UR)}, a Contribuição Básica é fixa em
            2%, o valor mínimo para Contribuição Básica é de{' '}
            {formatValue(minContrib)}, este valor é atualizado todos os anos em
            janeiro.
          </p>
          <Line />
          <strong>CONTRIBUIÇÃO BÁSICA</strong>
          <p>
            A sua Contribuição Básica mensal é calculada de acordo com seu
            salário de participação e com percentual definido na adesão. Para
            cada Contribuição Básica feita, a empresa fará um depósito adicional
            em seu nome.
          </p>
          <Line />
          <strong>CONTRIBUIÇÃO VOLUNTÁRIA</strong>
          <p>
            A Contribuição Voluntária pode ser realizada mensalmente até o
            limite máximo de 10% do seu salário de participação, porém ela não
            possui o repique da empresa.
          </p>
        </Modal>
      </Container>
    </>
  )
}

export default ContributionP2
