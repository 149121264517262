import styled from 'styled-components'
import { transparentize } from 'polished'

interface GoalItemProps {
  isSelected: boolean
}

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 0 25px 20px 25px;
  margin-bottom: 10px;
  text-align: center;



  .app_title {
    display: flex;
    width: 100%;
    justify-items: center;

    strong {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: ${props => props.theme.colors.text};

      display: flex;
      justify-content: center;
      margin: 0 0 15px 22.5px;
    }

    svg {
      width: 22.5px;
      height: 22.5px;
      color: #005F27;
      cursor: pointer;
    }
  }

  > strong {
    text-align: center;
    font-size: 18px;

    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  /* span {
    margin-bottom: 15px;
  } */

  small {
    margin-top: 15px;
  }

  footer {
    font-size: 12px;
  }
  a {
    color: #005F27;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: #055535;
    }
  }

  table {
    margin-bottom: 16px;
    th {
      font-weight: normal;
    }
    td {
      text-align: start;
      padding: 0 4px;
    }
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;
  }

  div.switch {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 25px;
    text-align: right;

    small {
      margin: 0 12px;
      font-size: 14px;
    }

    a {
      text-decoration: none;
    }
  }
`

export const InvestorBox = styled.div`
  display: inline-table;
  /* grid-template-columns: 1fr 1fr; */
  /* grid-gap: 15px; */
  align-items: center;
  justify-content:space-evenly;

  margin: 20px auto;
`

export const GoalItem = styled.button<GoalItemProps>`
  width: 152px;
  height: 65px;
  margin: 8px;
  vertical-align: bottom;
  padding: 12px;

  background: transparent;
  border-radius: 4px;
  border: 1px solid ${props => props.isSelected
    ? transparentize(0.4, props.theme.colors.mainColor)
    : transparentize( 0.4, '#AEAEAE')};

  font-weight: ${props => props.isSelected
    ? 'bold'
    : 'normal'};
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`

export const Line = styled.div`
  width: 65%;
  height: 1px;
  margin: 15px auto 20px;
  background-color: ${props => props.theme.colors.backgroundTertiary};
`
